import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, FormControl, RadioGroup, FormControlLabel, FormLabel, Radio, Checkbox, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { store } from "../../store";
import Leaderboard from "../Leaderboard";
import jeziki from "./../../jeziki";
import { AuthContext } from "./../../auth";
import { database } from "./../../firebase";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Container from "@mui/material/Container";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
/* import Dice from "./Dice"; */
import Dice from "react-dice-roll";
import service from "./../service";
import { shuffle } from "../../helpers/shuffle";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import igra from "./../../Logotipi/igra.jpg";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

let colorArray = ["red", "green", "yellow", "blue"];
export default function GameOptions() {
  const authContext = React.useContext(AuthContext);
  const history = useHistory();
  const { state, dispatch } = React.useContext(store);
  const [gamesize, setGamesize] = React.useState(state.options ? state.options.grid : "4*5");
  const [isVisible, setIsVisible] = React.useState(state.options ? state.options.isVisible : false);
  const [games, setGames] = useState([]);
  const [currentTutorial, setCurrentTutorial] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [gameCreated, setGameCreated] = useState(false);
  const [firstThrow, setFirstThrow] = useState([true, true, true, true]);
  const [playerLocations, setPlayerLocations] = useState([
    { color: "red", position: 0 },
    { color: "blue", position: 1 },
    { color: "yellow", position: 2 },
    { color: "green", position: 3 },
  ]);
  const [playerTurn, setPlayerTurn] = useState(0);
  const [circles, setCircles] = useState([
    {
      left: 61,
      top: 685,
    },
    {
      left: 116,
      top: 688,
    },
    {
      left: 115,
      top: 734,
    },
    {
      left: 59,
      top: 732,
    },
    {
      left: 163,
      top: 713,
    },
    {
      left: 205,
      top: 692,
    },
    {
      left: 245,
      top: 674,
    },
    {
      left: 285,
      top: 661,
    },
    {
      left: 329,
      top: 658,
    },
    {
      left: 374,
      top: 662,
    },
    {
      left: 419,
      top: 675,
    },
    {
      left: 464,
      top: 692,
    },
    {
      left: 507,
      top: 708,
    },
    {
      left: 552,
      top: 713,
    },
    {
      left: 596,
      top: 716,
    },
    {
      left: 641,
      top: 712,
    },
    {
      left: 687,
      top: 704,
    },
    {
      left: 732,
      top: 693,
    },
    {
      left: 773,
      top: 674,
    },
    {
      left: 816,
      top: 665,
    },
    {
      left: 864,
      top: 666,
    },
    {
      left: 906,
      top: 677,
    },
    {
      left: 949,
      top: 698,
    },
    {
      left: 996,
      top: 706,
    },
    {
      left: 1038,
      top: 692,
    },
    {
      left: 1059,
      top: 651,
    },
    {
      left: 1066,
      top: 606,
    },
    {
      left: 1035,
      top: 567,
    },
    {
      left: 997,
      top: 548,
    },
    {
      left: 950,
      top: 532,
    },
    {
      left: 906,
      top: 537,
    },
    {
      left: 863,
      top: 543,
    },
    {
      left: 817,
      top: 551,
    },
    {
      left: 773,
      top: 554,
    },
    {
      left: 727,
      top: 555,
    },
    {
      left: 679,
      top: 548,
    },
    {
      left: 638,
      top: 531,
    },
    {
      left: 596,
      top: 513,
    },
    {
      left: 551,
      top: 503,
    },
    {
      left: 506,
      top: 504,
    },
    {
      left: 463,
      top: 519,
    },
    {
      left: 423,
      top: 541,
    },
    {
      left: 380,
      top: 557,
    },
    {
      left: 334,
      top: 566,
    },
    {
      left: 301,
      top: 529,
    },
    {
      left: 270,
      top: 499,
    },
    {
      left: 264,
      top: 452,
    },
    {
      left: 288,
      top: 412,
    },
    {
      left: 326,
      top: 382,
    },
    {
      left: 372,
      top: 393,
    },
    {
      left: 415,
      top: 394,
    },
    {
      left: 464,
      top: 386,
    },
    {
      left: 507,
      top: 375,
    },
    {
      left: 554,
      top: 363,
    },
    {
      left: 599,
      top: 363,
    },
    {
      left: 645,
      top: 372,
    },
    {
      left: 691,
      top: 388,
    },
    {
      left: 729,
      top: 409,
    },
    {
      left: 770,
      top: 426,
    },
    {
      left: 818,
      top: 439,
    },
    {
      left: 862,
      top: 450,
    },
    {
      left: 906,
      top: 453,
    },
    {
      left: 956,
      top: 458,
    },
    {
      left: 997,
      top: 448,
    },
    {
      left: 1038,
      top: 422,
    },
    {
      left: 1063,
      top: 387,
    },
    {
      left: 1064,
      top: 344,
    },
    {
      left: 1039,
      top: 309,
    },
    {
      left: 1000,
      top: 283,
    },
    {
      left: 959,
      top: 266,
    },
    {
      left: 917,
      top: 265,
    },
    {
      left: 870,
      top: 274,
    },
    {
      left: 825,
      top: 290,
    },
    {
      left: 783,
      top: 287,
    },
    {
      left: 742,
      top: 286,
    },
    {
      left: 699,
      top: 279,
    },
    {
      left: 656,
      top: 270,
    },
    {
      left: 614,
      top: 255,
    },
    {
      left: 569,
      top: 243,
    },
    {
      left: 530,
      top: 227,
    },
    {
      left: 482,
      top: 220,
    },
    {
      left: 437,
      top: 225,
    },
    {
      left: 394,
      top: 228,
    },
    {
      left: 353,
      top: 239,
    },
    {
      left: 308,
      top: 252,
    },
    {
      left: 265,
      top: 260,
    },
    {
      left: 217,
      top: 268,
    },
    {
      left: 178,
      top: 274,
    },
    {
      left: 133,
      top: 264,
    },
    {
      left: 107,
      top: 228,
    },
    {
      left: 92,
      top: 182,
    },
    {
      left: 96,
      top: 140,
    },
    {
      left: 129,
      top: 101,
    },
    {
      left: 170,
      top: 80,
    },
    {
      left: 215,
      top: 67,
    },
    {
      left: 263,
      top: 64,
    },
    {
      left: 308,
      top: 59,
    },
    {
      left: 354,
      top: 63,
    },
    {
      left: 399,
      top: 66,
    },
    {
      left: 447,
      top: 72,
    },
    {
      left: 495,
      top: 82,
    },
    {
      left: 541,
      top: 93,
    },
    {
      left: 586,
      top: 105,
    },
    {
      left: 633,
      top: 117,
    },
    {
      left: 680,
      top: 126,
    },
    {
      left: 726,
      top: 135,
    },
    {
      left: 775,
      top: 141,
    },
    {
      left: 823,
      top: 144,
    },
    {
      left: 869,
      top: 136,
    },
    {
      left: 913,
      top: 125,
    },
    {
      left: 960,
      top: 106,
    },
    {
      left: 1004,
      top: 88,
    },
    {
      left: 1092,
      top: 60,
    },
  ]);
  useEffect(() => {
    service.getAll().on("value", onDataChange);
    console.log("startup");
    return () => {
      service.getAll().off("value", onDataChange);
      console.log("cleanup");
      if (gameCreated) database.ref(`games/${gameCreated}`).remove();
    };
  }, []);

  const refreshList = () => {
    setCurrentTutorial(null);
    setCurrentIndex(-1);
  };

  useEffect(() => {
    if (gameCreated) {
      let activeGame = games.find((game) => game.key === gameCreated);
      if (activeGame) {
        if (activeGame.started) {
          dispatch({
            type: "GAME_OPTIONS",
            payload: { grid: gamesize, isVisible, gameId: gameCreated },
          });
          dispatch({
            type: "RESET",
          });
          history.push(`/game-1/${gameCreated}`);
        }
      }
    }
  });

  const onDataChange = (items) => {
    let games = [];
    items.forEach((item) => {
      let key = item.key;
      let data = item.val();
      games.push({
        key: key,
        title: data.creator,
        grid: data.grid,
        gameRef: data.gameRef,
        creator: data.creator,
        creatorUsername: data.creatorUsername,
        photoUrl: data.photoUrl,
        players: data.players,
        started: data.started,
      });
    });
    setGames(games);
    //console.log(games);
  };

  const startGame = () => {
    //console.log("asd");
    dispatch({
      type: "GAME_OPTIONS",
      payload: { grid: gamesize, isVisible, gameId: gameCreated },
    });
    dispatch({
      type: "RESET",
    });
    database.ref(`games/${gameCreated}`).update({
      started: true,
    });
    history.push(`/game-1/${gameCreated}`);
    authContext.setActiveGame(gameCreated);
  };
  const deleteGame = (gameCreated) => {
    database.ref(`games/${gameCreated}`).remove();
    dispatch({
      type: "GAME_OPTIONS",
      payload: { grid: gamesize, isVisible, gameId: "" },
    });
    setGameCreated(false);
  };

  const activeGames = () => {
    /* return database
      .ref("games")
      .once("value")
      .then((snapshot) => {
        snapshot.forEach((igra) => {
          console.log(igra.val());
          let gameData = igra.val();
          return (
            <Card>
              <h1>Creator: {gameData.creatorUsername}</h1>
              <h1>Grid: {gameData.grid}</h1>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt={gameData.displayName} src={gameData.photoURL ? gameData.photoURL : ""} />
                </ListItemAvatar>
                <ListItemText
                  primary="Oui Oui"
                  secondary={
                    <React.Fragment>
                      <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                        Sandra Adams
                      </Typography>
                      {" — Do you have Paris recommendations? Have you ever…"}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </Card>
          );
        });
      }); */
  };
  const pridruziSeIgri = (gameRef) => {
    let userName = authContext.user.displayName
      ? authContext.user.displayName
      : authContext.user.email.substring(0, authContext.user.email.lastIndexOf("@"));
    let colorChoice = "green";
    let colorArray = ["red", "green", "yellow", "blue"];
    //color choice here
    let playersRef = games.find((game) => game.key === gameRef).players;
    colorChoice = colorArray[Object.keys(playersRef).length - 1]; //-2 ker gre od 0 in ker je not še en array, ki ni igralec
    let colorNumber = Object.keys(playersRef).length - 1;
    database.ref(`games/${gameRef}/players/${authContext.user.uid}`).update({
      gameRef: gameRef,
      //playerRef: refPlayer.key,
      uid: authContext.user.uid,
      creatorUsername: userName,
      playerUsername: userName,
      photoUrl: authContext.user.photoURL,
      points: 0,
      isReady: false,
      isTurn: false,
      color: colorChoice,
      position: colorNumber,
      firstThrow: true,
    });
    let playersJoinedArrayCurrent = games.find((game) => game.key === gameRef).players.playersJoinedArray;
    playersJoinedArrayCurrent.push(authContext.user.uid);
    database.ref(`games/${gameRef}/players/playersJoinedArray`).update(playersJoinedArrayCurrent);
    setGameCreated(gameRef);
    authContext.setActiveGame(gameRef);
    /* database
      .ref(`games/${gameRef}/players`)
      .push()
      .then((refPlayer) => {
        database.ref(`games/${gameRef}/players`).push({
          gameRef: gameRef,
          playerRef: refPlayer.key,
          uid: authContext.user.uid,
          playerUsername: userName,
          photoUrl: authContext.user.photoURL,
          points: 0,
          isReady: false,
          isTurn: false,
        });
        setGameCreated(gameRef);
        authContext.setActiveGame(gameRef);
      }); */
  };
  const leaveGame = (gameRef) => {
    let userName = authContext.user.displayName
      ? authContext.user.displayName
      : authContext.user.email.substring(0, authContext.user.email.lastIndexOf("@"));
    let playersJoinedArrayCurrent = games.find((game) => game.key === gameRef).players.playersJoinedArray;
    playersJoinedArrayCurrent = playersJoinedArrayCurrent.filter((e) => e !== authContext.user.uid);
    database.ref(`games/${gameRef}/players/${authContext.user.uid}`).remove();
    //database.ref(`games/${gameRef}/players/playersJoinedArray`).set(false);
    database.ref(`games/${gameRef}/players/playersJoinedArray`).set(playersJoinedArrayCurrent);
    setGameCreated(false);
    authContext.setActiveGame(false);
    /* let userName = authContext.user.displayName
      ? authContext.user.displayName
      : authContext.user.email.substring(0, authContext.user.email.lastIndexOf("@"));
      database
      .ref(`games/${gameRef}/players`)
      .push()
      .then((refPlayer) => {
        database.ref(`games/${gameRef}/players`).push({
          gameRef: gameRef,
          playerRef: refPlayer.key,
          uid: authContext.user.uid,
          playerUsername: userName,
          photoUrl: authContext.user.photoURL,
          points: 0,
          isReady: false,
          isTurn: false,
        });
        setGameCreated("");
      }); */
  };
  const createGame = () => {
    let userName = authContext.user.displayName
      ? authContext.user.displayName
      : authContext.user.email.substring(0, authContext.user.email.lastIndexOf("@"));
    let size = eval(gamesize) / 2;
    let i = 0;
    let data = [...state.data.slice(0, size), ...state.data.slice(0, size)].map((item) => {
      return { ...item, id: i++ };
    });
    data = shuffle(data);
    database
      .ref("games")
      .push()
      .then((ref) => {
        setGameCreated(ref.key);
        database.ref(`games/${ref.key}`).update({
          gameRef: ref.key,
          creator: authContext.user.uid,
          photoUrl: authContext.user.photoURL,
          creatorUsername: userName,
          grid: gamesize,
          started: false,
          gridData: data,
          flipped: false,
          //playersJoinedArray: [authContext.user.uid],
        });
        database.ref(`games/${ref.key}/players/playersJoinedArray`).update([authContext.user.uid]);
        database.ref(`games/${ref.key}/players/${authContext.user.uid}`).update({
          gameRef: ref.key,
          //playerRef: refPlayer.key,
          uid: authContext.user.uid,
          creatorUsername: userName,
          playerUsername: userName,
          photoUrl: authContext.user.photoURL,
          points: 0,
          isReady: false,
          isTurn: true,
          color: "red",
          position: 0,
          firstThrow: true,
        });
        authContext.setActiveGame(ref.key);
        /* database
          .ref(`games/${ref.key}/players`)
          .push()
          .then((refPlayer) => {
            database.ref(`games/${ref.key}/players`).push({
              gameRef: ref.key,
              playerRef: refPlayer.key,
              uid: authContext.user.uid,
              creatorUsername: userName,
              playerUsername: userName,
              photoUrl: authContext.user.photoURL,
              points: 0,
              isReady: false,
              isTurn: false,
            });
          }); */
      });
  };
  /* const onClickImage = (event) => {
    setCircles((prevData) => [...prevData, { left: event.clientX - 21, top: event.clientY - 86 }]);
    //console.log(event.clientX + " " + event.clientY);
  }; */
  //console.log(circles);
  const diceThrown = (value) => {
    let novoStanje = [...playerLocations];
    let polje = novoStanje[playerTurn].position + value;
    if (polje === 19) polje = 31;
    else if (polje === 38) polje = 51;
    else if (polje === 56) polje = 72;
    else if (polje === 80) polje = 96;

    let novoStanjePrvihMetov = firstThrow;
    if (novoStanjePrvihMetov[playerTurn] && playerTurn === 0) {
      polje += 3;
      novoStanjePrvihMetov[playerTurn] = false;
    }
    if (novoStanjePrvihMetov[playerTurn] && playerTurn === 1) {
      polje += 2;
      novoStanjePrvihMetov[playerTurn] = false;
    }
    if (novoStanjePrvihMetov[playerTurn] && playerTurn === 2) {
      polje += 1;
      novoStanjePrvihMetov[playerTurn] = false;
    }
    novoStanje[playerTurn].position = polje;
    setPlayerLocations(novoStanje);
    let newPlayer = playerTurn + 1;
    newPlayer = newPlayer % 4;
    setPlayerTurn(newPlayer);
    setFirstThrow(novoStanjePrvihMetov);
  };
  const setPlayerColor = (color) => {
    //console.log(games);
    database.ref(`games/${authContext.activeGame}/players/${authContext.user.uid}`).update({
      color: color,
    });
    //console.log(games.find((game) => game.key === gameCreated).players.find((player) => player.playerUsername === authContext.user.displayName));
    //.color === color
  };
  return (
    <>
      {/* <Paper elevation={3} style={{ width: "100%" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography component="h1" variant="h5" style={{ paddingLeft: "5px" }}>
                {jeziki[authContext.language].textIgralci}
              </Typography>
              <hr style={{ marginBottom: "5px" }}></hr>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Typography component="h1" variant="h5" style={{ paddingRight: "5px" }}>
                {jeziki[authContext.language].kocka}
              </Typography>
              <hr style={{ marginBottom: "5px" }}></hr>
              <Dice size={100} onRoll={(value) => diceThrown(value)} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <TransformWrapper initialScale={1}>
        {({ zoomIn, zoomOut, resetTransform, setTransform, ...rest }) => (
          <React.Fragment>
            <div className="tools">
              <button onClick={() => zoomIn()}>+</button>
              <button onClick={() => zoomOut()}>-</button>
              <button onClick={() => resetTransform()}>x</button>
              <button onClick={() => setTransform()}>set transform</button>
            </div>
            <TransformComponent>
              <div style={{ position: "relative" }}>
                <img src={igra} alt="test" style={{ width: "1200px", height: "100%" }} />
                {circles.map((circle, circleIndex) => {
                  let color = "transparent";
                  playerLocations.forEach((player) => {
                    if (player.position === circleIndex) color = player.color;
                  });
                  //if (playerOnField) color = circle.color;
                  return (
                    <div
                      key={`field-${circleIndex}`}
                      id={`field-${circleIndex}`}
                      style={{
                        //textAlign: "center",
                        //color: "white",
                        //border: "2px solid #ffffff",
                        //fontSize: "30px", 
                        position: "absolute",
                        borderRadius: "25px",
                        top: circle.top + "px",
                        left: circle.left + "px",
                        width: "35px",
                        height: "35px",
                        backgroundColor: color,
                        //backgroundColor: playerLocation === circleIndex ? "blue" : "transparent",
                      }}
                    >
                    </div>
                  );
                })}
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper> */}

      {
        //<Leaderboard></Leaderboard>
      }
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: "80vh" }}>
        {games.length > 0 && !gameCreated ? (
          <Paper
            elevation={8}
            sx={{ marginRight: { xs: "none", md: "5px" } }}
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
              width: "100%",
              padding: "10px",
              gap: "10px",
            }}
          >
            <Typography component="h1" variant="h5">
              {jeziki[authContext.language].textAktivneIgre}
            </Typography>

            {games.length > 0
              ? games.map((game, index) => {
                  if (game.started) return;
                  return (
                    <Card
                      key={index + "games"}
                      className="leader-items"
                      style={{
                        margin: "5px",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Avatar alt={game.creatorUsername} src={game.photoUrl} />
                      <div>
                        <h3>{game.creatorUsername}</h3>
                        <hr />
                        {/* <p>
                          {jeziki[authContext.language].textVelikostPolja} : {eval(game.grid)}
                        </p> */}
                      </div>
                      <div>
                        <h3>{jeziki[authContext.language].textIgralci}</h3>
                        <hr />
                        {game.players
                          ? Object.keys(game.players).map(function (key, index) {
                              return <p key={key + "playersGame" + game.title}>{game.players[key].playerUsername}</p>;
                            })
                          : ""}
                      </div>
                      <Grid
                        item
                        //className={"list-group-item " + (index === currentIndex ? "active" : "")}
                        //onClick={() => setActiveTutorial(tutorial, index)}
                        key={index}
                      >
                        <Button
                          disabled={game.players ? (Object.keys(game.players).length < 5 ? false : true) : true}
                          style={{ backgroundColor: "#3fb550" }}
                          fullWidth
                          variant="contained"
                          onClick={() => pridruziSeIgri(game.gameRef)}
                          sx={{ mt: 3, mb: 2 }}
                          startIcon={<VideogameAssetIcon />}
                        >
                          {jeziki[authContext.language].textPridruziSe}
                        </Button>
                      </Grid>
                    </Card>
                  );
                })
              : ""}
          </Paper>
        ) : (
          ""
        )}
        {games.find((game) => game.key === gameCreated) ? (
          <Grid item>
            <Card style={{ padding: "5px" }}>
              <CardContent>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Typography component="h1" variant="h5">
                    {jeziki[authContext.language].textIzbranaIgra}
                  </Typography>
                  <div style={{ height: "20px", width: "100%" }}></div>
                  <Avatar
                    alt={games.find((game) => game.key === gameCreated).creatorUsername}
                    src={games.find((game) => game.key === gameCreated).photoUrl}
                  />
                  <div style={{ height: "20px", width: "100%" }}></div>
                  <div style={{ width: "100%" }}>
                    <h3>{games.find((game) => game.key === gameCreated).creatorUsername}</h3>
                    <hr />
                    {/* <p>
                      {jeziki[authContext.language].textVelikostPolja} : {eval(games.find((game) => game.key === gameCreated).grid)}
                    </p> */}
                  </div>
                  {/* <FormControl component="fieldset" style={{ margin: "10px" }}>
                    <FormLabel component="legend">{jeziki[authContext.language].textMojaBarva}</FormLabel>
                    <RadioGroup
                      aria-label="game"
                      name="game"
                      value={gamesize}
                      onChange={(event) => {
                        setPlayerColor(event.target.value);
                      }}
                    >
                      
                      {console.log(games.find((game) => game.key === gameCreated))}
                      {games.find((game) => game.key === gameCreated)
                        ? colorArray.map((color) => {
                            //.players.find((player) => player.playerUsername === authContext.user.displayName).color === color
                            return (
                              <FormControlLabel
                                // tuttttututuututuututuutututuuututuutut
                                checked={games.find((game) => game.key === gameCreated).players[authContext.user.uid].color === color}
                                key={`${color}COLORSELECTION`}
                                value={color}
                                control={<Radio />}
                                label={jeziki[authContext.language][color]}
                              />
                            );
                          })
                        : ""}
                    </RadioGroup>
                  </FormControl> */}
                  <div style={{ height: "20px", width: "100%" }}></div>
                  <div style={{ width: "100%" }}>
                    <h3>{jeziki[authContext.language].textIgralci}</h3>
                    <hr />
                    {games.find((game) => game.key === gameCreated).players
                      ? Object.keys(games.find((game) => game.key === gameCreated).players).map(function (key, index) {
                          return (
                            <p key={key + "playersGame" + games.find((game) => game.key === gameCreated).title}>
                              {games.find((game) => game.key === gameCreated).players[key].playerUsername}
                            </p>
                          );
                        })
                      : ""}
                  </div>
                  <div style={{ height: "30px", width: "100%" }}></div>
                  {games.find((game) => game.key === gameCreated).creator === authContext.user.uid ? (
                    <>
                      <Button color="secondary" variant="contained" onClick={startGame} style={{ backgroundColor: "#3fb550", marginBottom: "10px" }}>
                        {jeziki[authContext.language].textZacni}
                      </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => deleteGame(gameCreated)}
                        style={{ backgroundColor: "#3fb550", marginBottom: "10px" }}
                      >
                        {jeziki[authContext.language].textNazaj}
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => leaveGame(gameCreated)}
                      style={{ backgroundColor: "#3fb550", marginBottom: "10px" }}
                    >
                      {jeziki[authContext.language].textNazaj}
                    </Button>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <Grid item sx={{ marginBottom: { xs: "60px" } }}>
            <Card style={{ padding: "5px" }}>
              <Typography component="h1" variant="h5">
                {jeziki[authContext.language].textUstvariNovoIgro}
              </Typography>
              <CardContent>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  {/* <FormControl component="fieldset" style={{ margin: "10px" }}>
                    <FormLabel component="legend">{jeziki[authContext.language].textVelikostPolja}</FormLabel>
                    <RadioGroup
                      aria-label="game"
                      name="game"
                      value={gamesize}
                      onChange={(event) => {
                        setGamesize(event.target.value);
                      }}
                    >
                      <FormControlLabel value="4*4" control={<Radio />} label="16" />
                      <FormControlLabel value="4*5" control={<Radio />} label="30" />
                      <FormControlLabel value="6*5" control={<Radio />} label="30" />
                      <FormControlLabel value="8*5" control={<Radio />} label="40" />
                    </RadioGroup>
                  </FormControl> */}

                  <Button color="secondary" variant="contained" onClick={createGame} style={{ backgroundColor: "#3fb550" }}>
                    {jeziki[authContext.language].textZacni}
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <Container sx={{ marginBottom: { xs: "200px", md: "0px" } }}></Container>
    </>
  );
}
