export default {
  si: {
    ime: "Slovenščina",
    imeIgre: "Družabna igra",
    tipIgre: "",
    navodila: [
      "Igra je primerna za 2–4 osebe.",
      "Figure postavimo na polje Start.",
      "Igro začne tisti, ki z igralno kocko vrže največje število. ",
      "Ko se izoblikuje vrstni red, se igra začne z novimi meti.",
      "Igralec, ki med igro vrže šestico, lahko met ponovi. ",
      "Tisti, ki pride na polje z lestvico, mora stopiti na polje, na katero ga popelje lestvica (gor ali dol).",
      "Če kateri od igralcev stopi na polje, na katerem je že drug igralec, slednji izpade in se mora vrniti na polje Start in igro začeti od začetka. ",
      "Zmaga tisti, ki prvi pride v cilj.",
      "Igralec lahko na ciljno polje stopi samo, če vrže natančno število. ",
    ],
    textVpis: "Vpiši se",
    textVpisGoogle: "Vpiši se z Google",
    jezik: "Izbira jezika",
    textEmail: "Elektronski naslov",
    textPassword: "Geslo",
    textPozabljenoGeslo: "Pozabljeno geslo?",
    textUstvariNovProfil: "Ustvari nov profil",
    textUstvari: "Ustvari nov profil",
    textImamProfil: "Že imaš profil? Vpiši se",
    textIzpisiSe: "Izpiši se",
    textZacni: "Začni",
    textVelikostPolja: "Število kartic",
    textNazaj: "Nazaj",
    textPonovno: "Začni znova",
    textCas: "Preostal čas",
    textRezultat: "Rezultat",
    textAli: "ALI",
    textLobby: "Čakalnica",
    errorFaieldToCreateAccount: "Napaka pri kreiranju novega uporabnika: ",
    errorEmailEmpty: "Polje za elektronski naslov je prazno!",
    errorEmailNotValid: "Elektronski naslov ni veljaven!",
    errorPasswordEmpty: "Polje za geslo je prazno!",
    errorPasswordShort: "Geslo je prekratko! (vsaj 6 znakov)",
    errorUserNotExist: "Uporabnik ne obstaja!",
    errorResetPassword: "Ni mogoče ponastaviti gesla: ",
    emailSent: "Pošta poslana! Prosim preverite svoj poštni predal!",
    leaderboard: "Lestvica najboljših",
    textAktivneIgre: "Aktivne igre",
    textUstvariNovoIgro: "Ustvari novo igro",
    textIzbranaIgra: "Izbrana igra",
    textIgralci: "Igralci",
    textPridruziSe: "Pridruži se",
    kocka: "Igralna kocka",
    textMojaBarva: "Izberi barvo",
    red: "Rdeča",
    blue: "Modra",
    yellow: "Rumena",
    green: "Zelena",
  },
  hu: {
    ime: "Magyar",
    imeIgre: "Társasjáték",
    tipIgre: "",
    navodila: [
      "A játékot 2–4 személy játszhatja.",
      "A bábukat a Start mezőre kell állítani. ",
      "Az kezdi a játékot, aki a dobókockával a legnagyobb számot dobja. ",
      "Amikor kialakul a sorrend, kezdetét veszi a játék újabb dobásokkal. ",
      "Aki a játék folyamán hatost dob, még egyszer dobhat. ",
      "Aki a létrával jelölt mezőre lép, az automatikusan átcsúszik arra a mezőre, ahova a létra vezet (fel vagy le). ",
      "Ha valaki olyan mezőre lép, ahol már egy másik játékos áll, akkor kiüti azt, s a másik játékosnak a Start mezőről kell újrakezdenie a játékot.",
      "Az nyeri a játékot, aki elsőként ér célba.",
      "Csak pontos számú dobással lehet a célba belépni.",
    ],
    textVpis: "Bejelentkezés",
    textVpisGoogle: "Google bejegyzés",
    jezik: "Nyelvválasztás",
    textEmail: "E-mail cím",
    textPassword: "Jelszó",
    textPozabljenoGeslo: "Elfeledett jelszó",
    textUstvariNovProfil: "Nincs még saját profilja? Hozzon létre egy új profilt!",
    textUstvari: "Új profil létrehozása",
    textImamProfil: "Van már profilja?",
    textIzpisiSe: "Kijelentkezés",
    textZacni: "Kezdés",
    textVelikostPolja: "Mező mérete",
    textNazaj: "Vissza",
    textPonovno: "Kezdje újra",
    textCas: "Hátralévő idő",
    textRezultat: "Eredmény",
    textAli: "VAGY",
    textLobby: "Váróterem",
    errorFaieldToCreateAccount: "Nem sikerült létrehoznia a profilját",
    errorEmailEmpty: "Az e-mail mező üres",
    errorEmailNotValid: "Érvénytelen E-mail cím ",
    errorPasswordEmpty: "A jelszó mező üres",
    errorPasswordShort: "A jelszó túl rövid! (legalább 6 jel)",
    errorUserNotExist: "A felhasználó nem létezik!",
    errorResetPassword: "Nem lehetséges a jelszót beálitása: ",
    emailSent: "Email elküldve! Kérjük, ellenőrizze a postaládáját!",
    leaderboard: "Ranglista",
    textAktivneIgre: "Aktív játékok",
    textUstvariNovoIgro: "Új játék létrehozása",
    textIzbranaIgra: "Kiválasztott játék",
    textIgralci: "Játékosok",
    textPridruziSe: "Csatlakozzon",
    kocka: "Dobókocka",
    textMojaBarva: "Válasszon színt",
    red: "Piros",
    blue: "Kék",
    yellow: "Sárga",
    green: "Zőld",
  },
  en: {
    ime: "English",
    imeIgre: "Board game",
    tipIgre: "",
    navodila: [
      "The game is for 2-4 players.",
      "Put the counters on Start. ",
      "The player that throws the highest number on the dice, starts the game. ",
      "Once the order is established, the game starts with a new round of throws. ",
      "If you throw a six, you get to throw again. ",
      "When a player lands on a ladder, they must move either up or down the ladder to the field that it takes them to. If one of the players lands on a spot already occupied by another player, the latter is out of the game and must return to the beginning and start again.",
      "The player who reaches the finish first is the winner.",
      "To reach the finish, players must throw the exact number needed to land on it.",
    ],
    textVpis: "Login",
    textVpisGoogle: "Login with Google",
    jezik: "Language select",
    textEmail: "Email Address",
    textPassword: "Password",
    textPozabljenoGeslo: "Forgot password?",
    textUstvariNovProfil: "Don't have an account? Sign up",
    textUstvari: "Sign up",
    textImamProfil: "Alredy have an account? Sign in",
    textIzpisiSe: "Sign out",
    textZacni: "Go",
    textVelikostPolja: "Number of cards",
    textNazaj: "Back",
    textPonovno: "Restart",
    textCas: "Time remaining",
    textRezultat: "Score",
    textAli: "OR",
    textLobby: "Lobby",
    errorFaieldToCreateAccount: "Failed to create an account: ",
    errorEmailEmpty: "Email field Empty!",
    errorEmailNotValid: "Email not valid!",
    errorPasswordEmpty: "Password empty!",
    errorPasswordShort: "Password too short! (at least 6 characters)",
    errorUserNotExist: "User doesn't exist!",
    errorResetPassword: "Cannot reset your password: ",
    emailSent: "Email sent! Please check your inbox!",
    leaderboard: "Leaderboard",
    textAktivneIgre: "Active games",
    textUstvariNovoIgro: "Create new game",
    textIzbranaIgra: "Selected game",
    textIgralci: "Players",
    textPridruziSe: "Join",
    kocka: "Dice",
    textMojaBarva: "Choose color",
    red: "Red",
    blue: "Blue",
    yellow: "Yellow",
    green: "Green",
  },
  de: {
    ime: "Deutsch",
    imeIgre: "Brettspiel",
    tipIgre: "",
    navodila: [
      "Für 2–4 Spieler geeignet.",
      "Die Spielfiguren auf das Startfeld positionieren. ",
      "Das Spiel beginnt der Spieler, der mit dem Spielwürfel die höchste Zahl wirft.  ",
      "Wenn die Reihenfolge der Spieler feststeht, beginnt das Spiel mit neuen Würfen. ",
      "Der Spieler, der während des Spiels eine „6“ würfelt, darf noch einmal würfeln. ",
      "Wer auf das Feld mit der Leiter tritt, muss auf das Feld weitertreten, auf welches die Leiter ausgerichtet ist (rauf oder runter).",
      "Wenn einer der Spieler auf ein Feld tritt, wo bereits ein anderer Spieler positioniert ist, muss der schon vorher dort positionierte Spieler zurück zum Startfeld und das Spiel neu beginnen. ",
      "Es gewinnt der Spieler, der zuerst das Ziel erreicht. ",
      "Der Spieler darf nur dann auf das Zielfeld treten, wenn er die genaue Zahl würfelt.  ",
    ],
    textVpis: "Anmeldung (melde dich für das Spiel an)",
    textVpisGoogle: "Googlev Anmeldung",
    jezik: "Sprachauswahl",
    textEmail: "E-mail-Adresse",
    textPassword: "Passwort",
    textPozabljenoGeslo: "Passwort wiederherstellen (vergessenes Passwort)",
    textUstvariNovProfil: "Ein neues Profil herstellen",
    textUstvari: "Ein neues Profil herstellen",
    textImamProfil: "Hast du bereits ein Profil? Melde dich an",
    textIzpisiSe: "Abmeldung ",
    textZacni: "Starte das Spiel",
    textVelikostPolja: "Spielfeld (das Spielbrett grösse)",
    textNazaj: "Zuruck",
    textPonovno: "Spiel neu starten",
    textCas: "Verbleibende Spielzeit",
    textRezultat: "Das Ergebnis",
    textAli: "ODER",
    textLobby: "Wartezimmer",
    errorFaieldToCreateAccount: "Benutzer konnte nicht erstellt werden: ",
    errorEmailEmpty: "E-Mail-Adresse ist leer",
    errorEmailNotValid: "E-Mail-Adresse ist nicht bekannt",
    errorPasswordEmpty: "Passwortfeld ist leer",
    errorPasswordShort: "Das Passwort ist zu kurz (mindestens 6 Zeichen)",
    errorUserNotExist: "Benutzer existiert nicht",
    errorResetPassword: "Ihr Passwort kann nicht zurückgesetz werden!",
    emailSent: "E-Mail versendet! Bitte dein Posteingang überprüfen!",
    leaderboard: "Bestenliste",
    textAktivneIgre: "Aktiv Spiele",
    textUstvariNovoIgro: "Erstelle ein neues Spiel",
    textIzbranaIgra: "Ausgewähltes Spiel",
    textIgralci: "Spielspieler",
    textPridruziSe: "Nimm am Spiel teil",
    kocka: "Würfel",
    textMojaBarva: "Wähle eine Farbe",
    red: "Rot",
    blue: "Blau",
    yellow: "Gelb",
    green: "Grün",
  },
};
