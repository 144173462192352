/* eslint-disable no-eval */
import React, { useState } from "react";
import GameCard from "./GameCard";
import { store } from "../../store";
import { shuffle } from "../../helpers/shuffle";
import { useHistory } from "react-router-dom";
import { Popup } from "./Popup";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import Paper from "@mui/material/Paper";
import { Grid, CardContent, FormControl, RadioGroup, FormControlLabel, FormLabel, Radio, Checkbox, Button } from "@material-ui/core";
import Card from "@mui/material/Card";
import jeziki from "./../../jeziki";
import { AuthContext } from "../../auth";
import { database } from "./../../firebase";
import service from "./../service";
import Avatar from "@mui/material/Avatar";
import Dice from "react-dice-roll";
import Typography from "@mui/material/Typography";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import igra from "./../../Logotipi/igra.jpg";
export default function GameGrid({ match }) {
  const { state, dispatch } = React.useContext(store);
  const [content, setContent] = React.useState([]);
  const [flipped, setFlipped] = React.useState([]);
  const [solved, setSolved] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [notMatch, setNotMatch] = React.useState(false);
  const authContext = React.useContext(AuthContext);
  const [sounds, setSounds] = React.useState(authContext.sounds);
  const [playerArray, setPlayerArray] = React.useState([]);
  const [playerData, setPlayerData] = React.useState({});
  const [isNotMyTurn, setIsNotMyTurn] = React.useState(true);
  const [isFinished, setIsFinished] = React.useState(false);
  const [firstThrow, setFirstThrow] = useState([true, true, true, true]);
  const [playerTurn, setPlayerTurn] = useState(0);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const audioOpen = new Audio();
  audioOpen.src = process.env.PUBLIC_URL + "/zvoki/open.wav";
  const audioMatch = new Audio();
  audioMatch.src = process.env.PUBLIC_URL + "/zvoki/match.mp3";
  const [circles, setCircles] = useState([
    {
      left: 61,
      top: 685,
    },
    {
      left: 116,
      top: 688,
    },
    {
      left: 115,
      top: 734,
    },
    {
      left: 59,
      top: 732,
    },
    {
      left: 163,
      top: 713,
    },
    {
      left: 205,
      top: 692,
    },
    {
      left: 245,
      top: 674,
    },
    {
      left: 285,
      top: 661,
    },
    {
      left: 329,
      top: 658,
    },
    {
      left: 374,
      top: 662,
    },
    {
      left: 419,
      top: 675,
    },
    {
      left: 464,
      top: 692,
    },
    {
      left: 507,
      top: 708,
    },
    {
      left: 552,
      top: 713,
    },
    {
      left: 596,
      top: 716,
    },
    {
      left: 641,
      top: 712,
    },
    {
      left: 687,
      top: 704,
    },
    {
      left: 732,
      top: 693,
    },
    {
      left: 773,
      top: 674,
    },
    {
      left: 816,
      top: 665,
    },
    {
      left: 864,
      top: 666,
    },
    {
      left: 906,
      top: 677,
    },
    {
      left: 949,
      top: 698,
    },
    {
      left: 996,
      top: 706,
    },
    {
      left: 1038,
      top: 692,
    },
    {
      left: 1059,
      top: 651,
    },
    {
      left: 1066,
      top: 606,
    },
    {
      left: 1035,
      top: 567,
    },
    {
      left: 997,
      top: 548,
    },
    {
      left: 950,
      top: 532,
    },
    {
      left: 906,
      top: 537,
    },
    {
      left: 863,
      top: 543,
    },
    {
      left: 817,
      top: 551,
    },
    {
      left: 773,
      top: 554,
    },
    {
      left: 727,
      top: 555,
    },
    {
      left: 679,
      top: 548,
    },
    {
      left: 638,
      top: 531,
    },
    {
      left: 596,
      top: 513,
    },
    {
      left: 551,
      top: 503,
    },
    {
      left: 506,
      top: 504,
    },
    {
      left: 463,
      top: 519,
    },
    {
      left: 423,
      top: 541,
    },
    {
      left: 380,
      top: 557,
    },
    {
      left: 334,
      top: 566,
    },
    {
      left: 301,
      top: 529,
    },
    {
      left: 270,
      top: 499,
    },
    {
      left: 264,
      top: 452,
    },
    {
      left: 288,
      top: 412,
    },
    {
      left: 326,
      top: 382,
    },
    {
      left: 372,
      top: 393,
    },
    {
      left: 415,
      top: 394,
    },
    {
      left: 464,
      top: 386,
    },
    {
      left: 507,
      top: 375,
    },
    {
      left: 554,
      top: 363,
    },
    {
      left: 599,
      top: 363,
    },
    {
      left: 645,
      top: 372,
    },
    {
      left: 691,
      top: 388,
    },
    {
      left: 729,
      top: 409,
    },
    {
      left: 770,
      top: 426,
    },
    {
      left: 818,
      top: 439,
    },
    {
      left: 862,
      top: 450,
    },
    {
      left: 906,
      top: 453,
    },
    {
      left: 956,
      top: 458,
    },
    {
      left: 997,
      top: 448,
    },
    {
      left: 1038,
      top: 422,
    },
    {
      left: 1063,
      top: 387,
    },
    {
      left: 1064,
      top: 344,
    },
    {
      left: 1039,
      top: 309,
    },
    {
      left: 1000,
      top: 283,
    },
    {
      left: 959,
      top: 266,
    },
    {
      left: 917,
      top: 265,
    },
    {
      left: 870,
      top: 274,
    },
    {
      left: 825,
      top: 290,
    },
    {
      left: 783,
      top: 287,
    },
    {
      left: 742,
      top: 286,
    },
    {
      left: 699,
      top: 279,
    },
    {
      left: 656,
      top: 270,
    },
    {
      left: 614,
      top: 255,
    },
    {
      left: 569,
      top: 243,
    },
    {
      left: 530,
      top: 227,
    },
    {
      left: 482,
      top: 220,
    },
    {
      left: 437,
      top: 225,
    },
    {
      left: 394,
      top: 228,
    },
    {
      left: 353,
      top: 239,
    },
    {
      left: 308,
      top: 252,
    },
    {
      left: 265,
      top: 260,
    },
    {
      left: 217,
      top: 268,
    },
    {
      left: 178,
      top: 274,
    },
    {
      left: 133,
      top: 264,
    },
    {
      left: 107,
      top: 228,
    },
    {
      left: 92,
      top: 182,
    },
    {
      left: 96,
      top: 140,
    },
    {
      left: 129,
      top: 101,
    },
    {
      left: 170,
      top: 80,
    },
    {
      left: 215,
      top: 67,
    },
    {
      left: 263,
      top: 64,
    },
    {
      left: 308,
      top: 59,
    },
    {
      left: 354,
      top: 63,
    },
    {
      left: 399,
      top: 66,
    },
    {
      left: 447,
      top: 72,
    },
    {
      left: 495,
      top: 82,
    },
    {
      left: 541,
      top: 93,
    },
    {
      left: 586,
      top: 105,
    },
    {
      left: 633,
      top: 117,
    },
    {
      left: 680,
      top: 126,
    },
    {
      left: 726,
      top: 135,
    },
    {
      left: 775,
      top: 141,
    },
    {
      left: 823,
      top: 144,
    },
    {
      left: 869,
      top: 136,
    },
    {
      left: 913,
      top: 125,
    },
    {
      left: 960,
      top: 106,
    },
    {
      left: 1004,
      top: 88,
    },
    {
      left: 1092,
      top: 60,
    },
  ]);
  const [playerLocations, setPlayerLocations] = useState([
    { color: "red", position: 0 },
    { color: "blue", position: 1 },
    { color: "yellow", position: 2 },
    { color: "green", position: 3 },
  ]);

  React.useEffect(() => {
    setSounds(authContext.sounds);
  }, [authContext]);

  React.useEffect(() => {
    service.getAllForGame(authContext.activeGame).on("value", onDataChange);
    return () => {
      service.getAllForGame(authContext.activeGame).off("value", onDataChange);
    };
  }, []);
  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  /* React.useEffect(() => {
    database.ref(`games/${authContext.activeGame}`).on("value", (snapshot) => {
      setContent(snapshot.val().gridData);
    });
  }, []); */

  const onDataChange = (gameData) => {
    console.log(gameData.val());
    if (gameData.val().flipped) {
      if (sounds) {
        audioOpen.play();
      }
      setFlipped(gameData.val().flipped);
    } else {
      delay(1000).then(() => {
        setFlipped([]);
      });
    }
    if (gameData.val().solved) setSolved(gameData.val().solved);
    setPlayerArray(gameData.val().players.playersJoinedArray);
    setIsNotMyTurn(!gameData.val().players[authContext.user.uid].isTurn);

    setDisabled(!gameData.val().players[authContext.user.uid].isTurn);
    setPlayerData(gameData.val().players);
    if (gameData.val().finished) setIsFinished(true);
    setContent(gameData.val().gridData);
    if (gameData.val().solved) if (gameData.val().solved.length === eval(gameData.val().grid)) setIsFinished(true);
  };

  /* React.useEffect(() => {
    database.ref(`games/${authContext.activeGame}/flipped`).on("value", (data) => {
      //console.log(data);
      //console.log(data.val());
      console.log("Before");
      console.log(flipped);
      console.log(data.val());
      if (flipped !== data.val()) {
        if (data.val()) {
          if (authContext.sounds) {
            audioOpen.play();
          }
          setFlipped(data.val());
        }
      }
      console.log("After");
      console.log(flipped);
      console.log(data.val());
    });
    return database.ref(`games/${authContext.activeGame}/flipped`).off("value");
  }); */
  const history = useHistory();
  function flip(id) {
    if (solved.includes(id)) return;
    console.log("DISABLED: " + disabled);
    setDisabled(true);
    if (flipped.length === 0) {
      //if the first card is flipped
      database.ref(`games/${authContext.activeGame}/flipped`).update([id]);
      setFlipped([id]);
      setDisabled(false);
    } else {
      //if the second card is flipped
      if (sameCardClicked(id)) {
        //setDisabled(false);
        return;
      } //and not the same as the first one
      //const updates = {};

      database.ref(`games/${authContext.activeGame}/flipped`).update([...flipped, id]);
      //updates[`games/${authContext.activeGame}/flipped`] = [...flipped, id];
      setFlipped([...flipped, id]);
      if (isMatch(id)) {
        // if the two cards are matched //success
        if (authContext.sounds) {
          audioMatch.play();
        }
        setSolved([...solved, ...flipped, id]);

        //database.ref(`games/${authContext.activeGame}/flipped`).remove();
        database.ref(`games/${authContext.activeGame}/flipped`).set(false);
        //updates[`games/${authContext.activeGame}/flipped`] = false;

        database.ref(`games/${authContext.activeGame}/solved`).update([...solved, ...flipped, id]);
        //updates[`games/${authContext.activeGame}/solved`] = [...solved, ...flipped, id];

        database.ref(`games/${authContext.activeGame}/players/${authContext.user.uid}/isTurn`).set(false);
        //updates[`games/${authContext.activeGame}/players/${authContext.user.uid}/isTurn`] = false;
        let currentPoints = playerData[authContext.user.uid].points;
        currentPoints = currentPoints + 5;

        database.ref(`games/${authContext.activeGame}/players/${authContext.user.uid}/points`).set(currentPoints);
        //updates[`games/${authContext.activeGame}/players/${authContext.user.uid}/points`] = currentPoints;
        let allPlayers = playerArray;
        let currentPlayer = authContext.user.uid;
        let currentIndex = allPlayers.indexOf(currentPlayer);
        let nextIndex = currentIndex % allPlayers.length;
        currentPlayer = allPlayers[nextIndex];

        database.ref(`games/${authContext.activeGame}/players/${currentPlayer}/isTurn`).set(true);
        //updates[`games/${authContext.activeGame}/players/${currentPlayer}/isTurn`] = false;

        setFlipped([]);
        //setDisabled(false);
        dispatch({
          type: "SUCCESS",
        });
      } else {
        //database.ref(`games/${authContext.activeGame}/flipped`).remove();
        database.ref(`games/${authContext.activeGame}/flipped`).set(false);
        //updates[`games/${authContext.activeGame}/flipped`] = false;

        database.ref(`games/${authContext.activeGame}/players/${authContext.user.uid}/isTurn`).set(false);
        //updates[`games/${authContext.activeGame}/players/${authContext.user.uid}/isTurn`] = false;

        let currentPoints = playerData[authContext.user.uid].points;
        currentPoints = currentPoints - 1;
        database.ref(`games/${authContext.activeGame}/players/${authContext.user.uid}/points`).set(currentPoints);
        //updates[`games/${authContext.activeGame}/players/${authContext.user.uid}/points`] = currentPoints;

        let allPlayers = playerArray;
        let currentPlayer = authContext.user.uid;
        let currentIndex = allPlayers.indexOf(currentPlayer);
        let nextIndex = (currentIndex + 1) % allPlayers.length;
        currentPlayer = allPlayers[nextIndex];
        database.ref(`games/${authContext.activeGame}/players/${currentPlayer}/isTurn`).set(true);
        //updates[`games/${authContext.activeGame}/players/${currentPlayer}/isTurn`] = true;

        dispatch({
          type: "FAILURE",
        });
        // if the two cards are not matched //failure

        setNotMatch(true);
      }
      /* delay(1000).then(() => {
        //database.ref().update(updates);
      }); */
    }
  }
  function isMatch(id) {
    const oldCard = content.find((card) => card.id === flipped[0]);
    const newCard = content.find((card) => card.id === id);

    return oldCard.name === newCard.name;
  }
  function sameCardClicked(id) {
    return flipped.includes(id);
  }
  React.useEffect(() => {
    if (
      /* eval(state.options ? state.options.grid : 0) === state.stats.trys || */
      state.stats.success ===
      eval(state.options ? state.options.grid : 0) / 2
    ) {
      dispatch({
        type: "OVER",
      });
    }
  }, [content.length, dispatch, state.options, state.stats.success, state.stats.trys]);
  React.useEffect(() => {
    if (!state.options) history.push("/game-1-options");
  }, [history, state.options]);

  React.useEffect(() => {
    if (state.options) {
      /*  // eslint-disable-next-line no-eval
      let size = eval(state.options.grid) / 2;
      let i = 0;
      let data = [...state.data.slice(0, size), ...state.data.slice(0, size)].map((item) => {
        return { ...item, id: i++ };
      }); */
      //setContent(shuffle(data));
    }
  }, [state.data, state.options]);
  React.useEffect(() => {
    if (!notMatch) return;
    const to = setTimeout(() => {
      setFlipped([]);
      setDisabled(false);
      setNotMatch(false);
    }, 500);

    return () => {
      clearTimeout(to);
    };
  }, [notMatch]);
  function restartGame() {
    if (disabled) return;
    if (state.options && state.options.isVisible) {
      setDisabled(true);
      setTimeout(() => {
        setSolved(content.map((i) => i.id));
      }, 500);
      setTimeout(() => {
        setSolved([]);
        setDisabled(false);
      }, 2000);
    }
    setSolved([]);
    setContent(shuffle(content));
    setFlipped([]);
    dispatch({
      type: "RESET",
    });
  }
  React.useEffect(() => {
    let to;
    if (state.options && state.options.isVisible) {
      setDisabled(true);
      setSolved(content.map((i) => i.id));
      to = setTimeout(() => {
        setSolved([]);
        setDisabled(false);
      }, 1500);
    }
    return () => clearTimeout(to);
  }, [content, state.options]);
  function goback() {
    dispatch({
      type: "RESET",
    });
    database.ref(`games/${state.options.gameId}`).update({
      started: false,
    });
    history.push("/game-1-options");
  }
  /* const cardContainerRef = React.useRef(null);
  React.useEffect(
    () => {
      let cardClientHeight = Math.round(cardContainerRef.current.clientHeight);
      let cardClientWidth = Math.round(cardContainerRef.current.clientWidth);
      let verticalHorizontal = cardClientHeight > cardClientWidth ? "vertical" : "horizontal";
      if (state.options) {
        let numberOfColumns = state.options.grid.toString().split("*")[0];
        let numberOfRows = state.options.grid.toString().split("*")[1];
        let cardWidth = Math.round(cardClientWidth / numberOfColumns);
        let cardHeight = Math.round(cardClientHeight / numberOfRows);
        if (verticalHorizontal === "vertical") {
          numberOfColumns = state.options.grid.toString().split("*")[1];
          numberOfRows = state.options.grid.toString().split("*")[0];
          cardWidth = Math.round(cardClientWidth / numberOfColumns);
          cardHeight = Math.round(cardClientHeight / numberOfRows);
        }
        let cardSize = Math.round(Math.min(cardWidth, cardHeight));
        setCardDimensions(cardSize - 22);
      }
    } // , [cardDimensions, state.options]
  ); */
  //console.log(playerData);
  const diceThrown = (value) => {
    setIsNotMyTurn(true);

    database.ref(`games/${authContext.activeGame}/players/${authContext.user.uid}/isTurn`).set(false);

    let currentPosition = playerData[authContext.user.uid].position;

    if (currentPosition === 0) {
      currentPosition += 3;
    } else if (currentPosition === 1) {
      currentPosition += 2;
    } else if (currentPosition === 2) {
      currentPosition += 1;
    }
    if (currentPosition + value > 112) {
    } else {
      currentPosition += value;
    }

    if (currentPosition === 19) currentPosition = 31;
    else if (currentPosition === 38) currentPosition = 51;
    else if (currentPosition === 56) currentPosition = 72;
    else if (currentPosition === 80) currentPosition = 96;
    else if (currentPosition === 31) currentPosition = 19;
    else if (currentPosition === 51) currentPosition = 38;
    else if (currentPosition === 72) currentPosition = 56;
    else if (currentPosition === 96) currentPosition = 80;

    database.ref(`games/${authContext.activeGame}/players/${authContext.user.uid}/position`).set(currentPosition);

    if (currentPosition === 112) database.ref(`games/${authContext.activeGame}/finished`).set(true);
    let allPlayers = playerArray;
    let currentPlayer = authContext.user.uid;
    let currentIndex = allPlayers.indexOf(currentPlayer);
    let nextIndex = (currentIndex + 1) % allPlayers.length;
    currentPlayer = allPlayers[nextIndex];
    database.ref(`games/${authContext.activeGame}/players/${currentPlayer}/isTurn`).set(true);

    /* let novoStanje = [...playerLocations];
    let polje = novoStanje[playerTurn].position + value;
    if (polje === 19) polje = 31;
    else if (polje === 38) polje = 51;
    else if (polje === 56) polje = 72;
    else if (polje === 80) polje = 96;

    let novoStanjePrvihMetov = firstThrow;
    if (novoStanjePrvihMetov[playerTurn] && playerTurn === 0) {
      polje += 3;
      novoStanjePrvihMetov[playerTurn] = false;
    }
    if (novoStanjePrvihMetov[playerTurn] && playerTurn === 1) {
      polje += 2;
      novoStanjePrvihMetov[playerTurn] = false;
    }
    if (novoStanjePrvihMetov[playerTurn] && playerTurn === 2) {
      polje += 1;
      novoStanjePrvihMetov[playerTurn] = false;
    }
    novoStanje[playerTurn].position = polje;
    setPlayerLocations(novoStanje);
    let newPlayer = playerTurn + 1;
    newPlayer = newPlayer % 4;
    setPlayerTurn(newPlayer);
    setFirstThrow(novoStanjePrvihMetov); */
  };

  return (
    <>
      <Popup restartGame={restartGame} isFinished={isFinished} playerData={playerData}></Popup>
      <Paper elevation={3} style={{ width: "100%" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography component="h1" variant="h5" style={{ paddingLeft: "5px" }}>
                {jeziki[authContext.language].textIgralci}
              </Typography>
              <hr style={{ marginBottom: "5px" }}></hr>
              {Object.keys(playerData).map((key, index) => {
                if (key !== "playersJoinedArray") {
                  let poz = playerData[key].position;
                  if (poz === 0) poz = 0;
                  else if (poz === 1) poz = 0;
                  else if (poz === 2) poz = 0;
                  else if (poz === 3) poz = 0;
                  else poz -= 3;
                  return (
                    <Card key={key} className={playerData[key].isTurn ? "leader-item currentPlayer" : "leader-item"}>
                      <h1>{playerData[key].isTurn ? <PlayCircleIcon style={{ color: "#90c858" }} /> : <PauseCircleIcon />}</h1>
                      <Avatar alt={`${playerData[key].playerUsername}`} src={`${playerData[key].photoUrl}`} />
                      <div>
                        <h3 className={authContext.user.uid === playerData[key].uid ? "currentPlayerText" : ""}>{playerData[key].playerUsername}</h3>
                        <hr />
                        <p>{poz}</p>
                      </div>
                    </Card>
                  );
                }
              })}
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Typography component="h1" variant="h5" style={{ paddingRight: "5px" }}>
                {jeziki[authContext.language].kocka}
              </Typography>
              <hr style={{ marginBottom: "5px" }}></hr>
              {/* {isNotMyTurn ? "" : <Dice size={100} disabled={} onRoll={(value) => diceThrown(value)} />} */}
              <Dice size={100} disabled={isNotMyTurn} onRoll={(value) => diceThrown(value)} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <div style={{ width: "100%" }}>
        <div style={{ display: "table", margin: "0 auto" }}>
          <TransformWrapper /* initialScale={width <= 768 ? 0.5 : 1} */ minScale={0.5} initialPositionX={0} limitToBounds={false} centerOnInit={true}>
            {({ zoomIn, zoomOut, resetTransform, setTransform, ...rest }) => (
              <React.Fragment>
                {/* <div className="tools">
              <button onClick={() => zoomIn()}>+</button>
              <button onClick={() => zoomOut()}>-</button>
              <button onClick={() => resetTransform()}>x</button>
              <button onClick={() => setTransform()}>set transform</button>
            </div> */}
                <TransformComponent>
                  {/* <div style={{ position: "relative", display: "table", margin: "0 auto" }}> */}
                  <img src={igra} alt="test" style={{ width: "1200px", height: "100%" }} />
                  {circles.map((circle, circleIndex) => {
                    let color = "transparent";
                    Object.keys(playerData).forEach((player) => {
                      if (player !== "playersJoinedArray") {
                        if (playerData[player].position === circleIndex) color = playerData[player].color;
                      }
                    });
                    /* playerLocations.forEach((player) => {
                    if (player.position === circleIndex) color = player.color;
                  }); */
                    //if (playerOnField) color = circle.color;
                    if (color === "transparent") {
                      return (
                        <div
                          key={`field-${circleIndex}`}
                          id={`field-${circleIndex}`}
                          style={{
                            //textAlign: "center",
                            //color: "white",
                            //border: "2px solid #ffffff",
                            //fontSize: "30px",
                            position: "absolute",
                            borderRadius: "25px",
                            top: circle.top + "px",
                            left: circle.left + "px",
                            width: "35px",
                            height: "35px",
                            backgroundColor: color,
                            //backgroundColor: playerLocation === circleIndex ? "blue" : "transparent",
                          }}
                        ></div>
                      );
                    } else {
                      return (
                        <EmojiPeopleIcon
                          key={`field-${circleIndex}`}
                          id={`field-${circleIndex}`}
                          style={{
                            //textAlign: "center",
                            //color: "white",
                            //border: "2px solid #ffffff",
                            //fontSize: "30px",
                            position: "absolute",
                            borderRadius: "25px",
                            top: circle.top - 10 + "px",
                            left: circle.left + "px",
                            width: "50px",
                            height: "50px",
                            //backgroundColor: color,
                          }}
                          sx={{ color: color }}
                        />
                      );
                    }
                  })}
                  {/* </div> */}
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        </div>
      </div>

      {/* <div className="game-grid" style={{ height: "100%" }}>
        <div className="game-grid-item stats">
          <div className="leader-items">
            {Object.keys(playerData).map((key, index) => {
              /* console.log(playerData);
              console.log(key);
              console.log(index); 
              if (key !== "playersJoinedArray") {
                return (
                  <Card key={key} className={playerData[key].isTurn ? "leader-item currentPlayer" : "leader-item"}>
                    <h1>{playerData[key].isTurn ? <PlayCircleIcon style={{ color: "#90c858" }} /> : <PauseCircleIcon />}</h1>
                    <Avatar alt={`${playerData[key].playerUsername}`} src={`${playerData[key].photoUrl}`} />
                    <div>
                      <h3 className={authContext.user.uid === playerData[key].uid ? "currentPlayerText" : ""}>{playerData[key].playerUsername}</h3>
                      <hr />
                      <p>{playerData[key].points}</p>
                    </div>
                  </Card> }
                  /*  <>
                  
                    <ListItem alignItems="flex-start" key={key}>
                      <ListItemAvatar>
                        <Avatar alt={`${playerData[key].playerUsername}`} src={`${playerData[key].photoUrl}`} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={playerData[key].playerUsername}
                        secondary={
                          <React.Fragment>
                            <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                              {playerData[key].points}
                            </Typography>
                            <CircleIcon />
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </> */
      /*      );
              }
            })}
          </div> 

          {/* <Box sx={{ display: { xs: "none", lg: "contents" } }}>
            <Button color="primary" variant="contained" onClick={goback} style={{ backgroundColor: "#47713e" }}>
              {jeziki[authContext.language].textNazaj}
            </Button>
            <h1>
              <div style={{ color: "#47713e" }}>
                {jeziki[authContext.language].textCas}:{counter}
              </div>
              {// Trys left:
              //{eval(state.options ? state.options.grid : 0) * 5 - state.stats.trys} }
            </h1>

            <h1 style={{ color: "#47713e" }}>
              {jeziki[authContext.language].textRezultat}:{state.stats.success * 5 - state.stats.failure}
            </h1>
            {// <Button color="secondary" variant="contained" onClick={restartGame} style={{ backgroundColor: "#f99c3f" }}>
              //{jeziki[authContext.language].textPonovno}
            //</Button> }
          </Box> }
          {/* <Box sx={{ display: { xs: "grid", lg: "none" } }}>
            <Button color="primary" variant="contained" onClick={goback} style={{ backgroundColor: "#47713e" }}>
              {jeziki[authContext.language].textNazaj}
            </Button>
            <h1>
              <div style={{ color: "#47713e" }}>
                {jeziki[authContext.language].textCas}:{counter}
              </div>
              {// Trys left:
              //{eval(state.options ? state.options.grid : 0) * 5 - state.stats.trys} }
            </h1>

            <h1 style={{ color: "#47713e" }}>
              {jeziki[authContext.language].textRezultat}:{state.stats.success * 5 - state.stats.failure}
            </h1>
            {// <Button color="secondary" variant="contained" onClick={restartGame} style={{ backgroundColor: "#f99c3f" }}>
              //{jeziki[authContext.language].textPonovno}
            //</Button> /}
          </Box> }
        {/* </div> }
        {/* <div className="game-grid-item" style={{ maxHeight: "80vh", height: "100%" }}>
          <Box sx={{ display: { xs: "none", lg: "block" } }}>
            <div style={{ width: "30vw", height: "100%" }}>
              <ImageList cols={1} sx={{ width: "100%", height: "100%" }} style={{ overflow: "hidden" }}>
                {/* {Array.from(new Set(solved)).map((item, index) => {
                  if (index % 2 === 0) return;
                  let element = content.find((slika) => slika.id === item);
                  return (
                    <ImageListItem key={element.id}>
                      <img src={`${element.flag}?w=248&fit=crop&auto=format`} alt={element.name} loading="lazy" />
                    </ImageListItem>
                  );
                })} }
                {solved.length > 0 ? (
                  <ImageListItem key={content.find((slika) => slika.id === solved[solved.length - 1]).id}>
                    <img
                      src={`https://pmsnsspomin.web.app/${
                        content.find((slika) => slika.id === solved[solved.length - 1]).flag
                      }?w=248&fit=crop&auto=format`}
                      alt={content.find((slika) => slika.id === solved[solved.length - 1]).name}
                      loading="lazy"
                    />
                  </ImageListItem>
                ) : (
                  <></>
                )}
              </ImageList>
            </div>
          </Box>
          <div className="card-container" ref={cardContainerRef} style={{ width: "auto", height: "100%" }}>
            {content.map((item) => {
              return (
                <GameCard
                  cardDimensions={cardDimensions}
                  key={item.id}
                  flipped={flipped.includes(item.id) || solved.includes(item.id)}
                  flip={flip}
                  data={item}
                  length={content.length}
                  disabled={isNotMyTurn || disabled} // && disabled}
                  solved={solved.includes(item.id)}
                ></GameCard>
              );
            })}
          </div>
        </div>
      </div> */}
      <Container sx={{ marginBottom: { xs: "200px", md: "0px" } }}></Container>
    </>
  );
}
