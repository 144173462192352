import React from "react";
import "./App.css";
import { store } from "./store";
import GamePicker from "./components/GamePicker";
import GameOptions from "./components/GameOne/GameOptions";
import GameGrid from "./components/GameOne/GameGrid";
import AppBar from "./AppBar";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AppBarBottom from "./AppBarBottom";
/*
//temno zelena    #47713e
//svetlo zelena   #90c858
//oranzna         #f99c3f
*/
function App() {
  const globalState = React.useContext(store);
  const { state, dispatch } = globalState;
  React.useEffect(() => {
    dispatch({
      type: "MAIN_DATA",
      payload: [
        { flag: "slike/1.jpg", name: "1", id: 1 },
        { flag: "slike/2.jpg", name: "2", id: 2 },
        { flag: "slike/3.jpg", name: "3", id: 3 },
        { flag: "slike/4.jpg", name: "4", id: 4 },
        { flag: "slike/5.jpg", name: "5", id: 5 },
        { flag: "slike/6.jpg", name: "6", id: 6 },
        { flag: "slike/7.jpg", name: "7", id: 7 },
        { flag: "slike/8.jpg", name: "8", id: 8 },
        { flag: "slike/9.jpg", name: "9", id: 9 },
        { flag: "slike/10.jpg", name: "10", id: 10 },
        { flag: "slike/11.jpg", name: "11", id: 11 },
        { flag: "slike/12.jpg", name: "12", id: 12 },
        { flag: "slike/13.jpg", name: "13", id: 13 },
        { flag: "slike/14.jpg", name: "14", id: 14 },
        { flag: "slike/15.jpg", name: "15", id: 15 },
        { flag: "slike/16.jpg", name: "16", id: 16 },
        { flag: "slike/17.jpg", name: "17", id: 17 },
        { flag: "slike/18.jpg", name: "18", id: 18 },
        { flag: "slike/19.jpg", name: "19", id: 19 },
        { flag: "slike/20.jpg", name: "20", id: 20 },
      ],
    });
  }, [dispatch]);

  return (
    <>
      <BrowserRouter>
        <AppBar></AppBar>
        <Switch>
          <Route path="/game-1-options">{state.data && <GameOptions></GameOptions>}</Route>
          <Route path="/game-1">{state.data && <GameGrid></GameGrid>}</Route>
          <Route path="/">
            <GamePicker></GamePicker>
          </Route>
          {/* <Route path="*">
            <GamePicker></GamePicker>
          </Route> */}
        </Switch>
        <AppBarBottom></AppBarBottom>
      </BrowserRouter>
    </>
  );
}

export default App;
