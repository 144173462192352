import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
const firebaseConfig = {
  apiKey: "AIzaSyA9BcS6fA1-GiP5Msytkqtl3dmhBt4wKV8",
  authDomain: "pmsnsdruzabnaigra.firebaseapp.com",
  databaseURL: "https://pmsnsdruzabnaigra-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "pmsnsdruzabnaigra",
  storageBucket: "pmsnsdruzabnaigra.appspot.com",
  messagingSenderId: "320616066174",
  appId: "1:320616066174:web:0e3cbe0981b4cfb1c67c2f",
  measurementId: "G-403N00185D",
};
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.firestore();
export const database = firebase.database();

export default firebase;
